import { Box, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList } from '@chakra-ui/react';
import { useResources } from 'datx-jsonapi-react';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UserIcon from '@/assets/icons/ic-user.svg';

import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import { selfServiceEventsURL } from '@/constants/self-service-dashboard-routes';
import { AccessFrom } from '@/enums/AccessFrom';
import { queryThinProfilesByUserId } from '@/queries/thin-profiles';
import { User } from '@/resources/User';

interface ICoreNavigationAuthenticatedDesktopProps extends MenuButtonProps {
  currentUser: User;
  onLogout?: () => void;
  accessFrom: AccessFrom;
}

export const CoreNavigationAuthenticatedDesktop: FC<ICoreNavigationAuthenticatedDesktopProps> = ({
  currentUser,
  onLogout,
  accessFrom,
  ...rest
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { data: currentUserProfileData } = useResources(queryThinProfilesByUserId(currentUser.id));

  const currentUserProfile = currentUserProfileData?.[0];

  return (
    <Menu computePositionOnMount variant="navigation" colorScheme="blackAlpha" placement="bottom-end">
      <MenuButton {...rest} ml="5">
        {currentUserProfile?.mediumSquareAvatarUrl ? (
          <Image
            width={32}
            height={32}
            alt="User avatar"
            src={currentUserProfile.mediumSquareAvatarUrl}
            style={{ borderRadius: '50%' }}
          />
        ) : (
          <Box h={8} w={8}>
            <UserIcon />
          </Box>
        )}
      </MenuButton>
      <MenuList color="white">
        {currentUserProfile && (
          <NextLink href={`/my-account/profile?accessFrom=${accessFrom}`} passHref legacyBehavior>
            <NavLink as={MenuItem} isActiveLink={router.asPath.startsWith('/log-in')}>
              {t('action.myProfile')}
            </NavLink>
          </NextLink>
        )}
        {currentUser.isEventManager ? (
          <NextLink href={selfServiceEventsURL} passHref legacyBehavior>
            <NavLink as={MenuItem}>{t('action.myOrganizedEvents')}</NavLink>
          </NextLink>
        ) : null}
        <MenuItem px={5} onClick={onLogout} textTransform="lowercase">
          {t('action.logOut')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
